import {Application} from "@hotwired/stimulus";

const application = Application.start()

application.debug = window.env === 'development'
window.Stimulus = application

// Generic controllers
const genericControllers = import.meta.globEager([
  "../controllers/**/*_controller.js",
  "../controllers/**/*_controller.ts"
]);

for (let path in genericControllers) {
  let module = genericControllers[path] as any;
  let name = path
    .match(/\/(.+)_controller\.(js|ts)$/)![1]
    .replaceAll("/", "-")
    .replaceAll("_", "-");

  application.register(name, module.default);
}

// Controllers from components
const controllers = import.meta.globEager([
  "./../../components/**/controller.js",
  "./../../components/**/controller.ts",
]);

for (let path in controllers) {
  let module = controllers[path] as any;
  let name = path
    .match(/components\/(.+)\/controller\.(js|ts)$/)![1]
    .replaceAll("/", "-")
    .replaceAll("_", "-");
  application.register(name, module.default);
}

export {application}
