import {Controller as BaseController} from "@hotwired/stimulus"

export default class extends BaseController<HTMLElement> {
  static values = {
    selected: Boolean
  }

  declare readonly selectedValue: boolean

  connect() {
    if (this.selectedValue) {
      this.element.scrollIntoView();
    }
  }
}
