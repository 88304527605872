import {Controller as BaseController} from "@hotwired/stimulus"
import PullToRefresh, {PullToRefreshInstance} from 'pulltorefreshjs';

export default class extends BaseController<HTMLElement> {
  static values = {
    options: Object
  }

  declare optionsValue: PullToRefresh.Options
  declare instance: PullToRefreshInstance

  connect() {
    this.instance = PullToRefresh.init({
      ...this.optionsValue,
      mainElement: 'body',
      shouldPullToRefresh(): any {
        return !window.scrollY && !document.body.classList.contains('modal-open')
      },
      onRefresh() {
        window.location.reload();
      }
    });
  }
}
