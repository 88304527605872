import {Controller as BaseController} from "@hotwired/stimulus"

import successSound from '../../../../assets/sounds/success.wav';
import errorSound from '../../../../assets/sounds/error.wav';

import {Howl} from "howler";

export default class extends BaseController<HTMLElement> {
  static values = {
    sound: String
  }

  declare sounds: {
    success: Howl,
    error: Howl,
  }

  declare readonly soundValue: 'success' | 'error'

  connect() {
    this.sounds = {
      success: new Howl({
        src: [successSound],
        html5: true,
      }),
      error:
        new Howl({
          src: [errorSound],
          html5: true,
        })
    }

    this.sounds[this.soundValue].play()
  }
}
