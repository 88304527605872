import {Controller as BaseController} from "@hotwired/stimulus"
import {differenceInSeconds, intervalToDuration} from "date-fns";

export default class extends BaseController<HTMLElement> {
  static values = {
    createdAt: String,
    fulfilledAt: {type: String, default: null},
    showWarning: {type: Boolean, default: true},
  }

  static targets = ["timer", "duration"]

  declare readonly createdAtValue: string
  declare readonly fulfilledAtValue: string
  declare readonly showWarningValue: boolean
  declare readonly timerTarget: HTMLElement
  declare readonly durationTarget: HTMLElement
  declare timer: ReturnType<typeof setInterval>

  connect() {
    this.updateDuration()

    this.timer = setInterval(() => {
      this.updateDuration()
    }, 1000);
  }

  disconnect() {
    clearInterval(this.timer)
  }

  updateDuration() {
    const isFulfilled = !!this.fulfilledAtValue
    const timeBase = isFulfilled ? this.fulfilledAtValue : new Date()
    const seconds = differenceInSeconds(timeBase, this.createdAtValue, {})
    const duration = intervalToDuration({start: 0, end: seconds * 1000})

    const zeroPad = (num: number) => String(num).padStart(2, '0')

    this.durationTarget.innerHTML = `${zeroPad((duration.hours || 0) * 60 + (duration.minutes || 0))}:${zeroPad(duration.seconds || 0)}`

    if (this.showWarningValue && !isFulfilled && seconds > 120.0) {
      this.timerTarget.classList.remove("text-white")
      this.timerTarget.classList.add("text-error-300")
      this.durationTarget.classList.add("animate", "animate-pulse")
    } else {
      this.timerTarget.classList.add("text-white")
      this.timerTarget.classList.remove("text-error-300")
      this.durationTarget.classList.remove("animate", "animate-pulse")
    }
  }
}
