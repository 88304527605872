import {Controller as BaseController} from "@hotwired/stimulus"

export default class extends BaseController<HTMLElement> {
  animate() {
    this.element.classList.remove('animate-fade')
    setTimeout(() => this.element.classList.add('animate-fade'), 1)
  }

  show() {
    this.element.classList.remove('hidden')
  }

  hide() {
    this.element.classList.add('hidden')
  }
}
