type IReaderState = 'idle' | 'reading';

interface IReaderOptions {
  onScan: (text: string) => Promise<void>;
  onStateChange?: (state: IReaderState) => void;
}

export default class KeyboardReader {
  declare isPaused: boolean;
  declare buffer: string;
  declare _state: IReaderState;
  declare onScan: (text: string) => Promise<void>;
  declare onStateChange?: (state: IReaderState) => void;
  declare eventListers: any;

  constructor({onScan, onStateChange}: IReaderOptions) {
    this.isPaused = false
    this.onScan = onScan
    this.onStateChange = onStateChange
    this.buffer = ''
    this.state = 'idle'

    this.eventListers = {
      onKeyDown: this.onKeyDown.bind(this),
      onKeyPress: this.onKeyPress.bind(this),
      onPaste: this.onPaste.bind(this),
    }
  }

  start() {
    addEventListener('keydown', this.eventListers.onKeyDown)
    addEventListener('keypress', this.eventListers.onKeyPress)
    addEventListener('paste', this.eventListers.onPaste)
  }

  stop() {
    removeEventListener('keydown', this.eventListers.onKeyDown)
    removeEventListener('keypress', this.eventListers.onKeyPress)
    removeEventListener('paste', this.eventListers.onPaste)
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Delete' || event.key === 'Clear') {
      console.log('Started reading from keyboard');

      this.buffer = '';
      this.state = 'reading';
    }
  }

  async onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.isPaused) {
      console.log('Finished reading from keyboard');

      try {
        this.isPaused = true;
        await this.onScan(this.buffer);
      } finally {
        this.isPaused = false;
        this.buffer = '';
        this.state = 'idle';
      }

      this.state = 'idle';
    } else if (event.key.length === 1) {
      this.buffer += event.key;
      this.state = 'reading';
    }
  };

  async onPaste(event: ClipboardEvent) {
    if (this.isPaused) {
      return;
    }
    console.log('Pasted from clipboard');

    if (event.clipboardData) {
      this.buffer = '';
      this.isPaused = true;
      await this.onScan(event.clipboardData.getData('text/plain'));
      this.state = 'idle';
      this.isPaused = false;
    }
  }

  get state() {
    return this._state;
  }

  set state(value: IReaderState) {
    const oldState = this._state;
    this._state = value;

    if (oldState !== value) {
      this.onStateChange?.(value);
    }
  }
}