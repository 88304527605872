import {Controller as BaseController} from "@hotwired/stimulus"

export default class extends BaseController<HTMLElement> {
  static values = {
    content: String,
    copy: String,
    copied: String,
  }

  declare readonly contentValue: string
  declare readonly copyValue: string
  declare readonly copiedValue: string

  static targets = ["message", "button"]

  declare messageTarget: HTMLElement

  startCopy() {
    this.messageTarget.innerText = this.copiedValue;
  }

  finishCopy() {
    this.messageTarget.innerText = this.copyValue;
  }

  copy() {
    navigator.clipboard.writeText(this.contentValue);

    if (this.copiedValue && this.copyValue) {
      this.messageTarget.innerText = this.copiedValue;

      this.startCopy();

      setTimeout(() => this.finishCopy(), 1000);
    }
  }
}
