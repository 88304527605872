import {Controller as BaseController} from "@hotwired/stimulus"

export default class extends BaseController<HTMLElement> {
  close() {
    this.element.classList.remove('max-h-64')
    this.element.classList.add('max-h-0', 'animate-fade-right')
    // setTimeout(() => {
    //   this.element.remove()
    // }, 300);
  }
}
